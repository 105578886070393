<template>
  <ModalBase size="xxxl" name="estimation-view-asset-specs">
    <template #default>
      <div class="flex flex-col space-y-10">
        <h3 class="text-center">
Asset specs
</h3>
        <div>-</div>
        <div class="flex flex-row items-center justify-end">
          <FormButton
            class="text-tertiary"
            accent="secondary"
            type="outline"
            @click="
              () => {
                $bvModal.hide('estimation-view-asset-specs');
              }
            "
          >
            Close
          </FormButton>
        </div>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import FormButton from '@/components/Form/FormButton.vue';
import ModalBase from '@/components/Modal/ModalBase.vue';

export default {
  name: 'ModalOrderEstimationViewAssetSpecs',
  components: {
    ModalBase,
    FormButton,
  },
  props: {
    payload: {
      validator: (value) => typeof value === 'object' || value === null,
      default: null,
    },
  },
};
</script>
